var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Ref Code Browse")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){var valueSend = [];
      _vm.sendToParentComponent(valueSend)}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.vDataTable.data,"search":_vm.vDataTable.search,"single-select":true,"show-select":"","fixed-header":"","page":_vm.vDataTable.options.page,"itemsPerPage":_vm.vDataTable.options.itemsPerPage,"options":_vm.vDataTable.options,"footer-props":{
        itemsPerPageText: _vm.$t('itemsPerPageText'),
        itemsPerPageAllText: _vm.$t('itemsPerPageAllText'),
        showFirstLastPage: true,
        showCurrentPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
      },"item-key":"skey"},on:{"update:page":function($event){return _vm.$set(_vm.vDataTable.options, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.vDataTable.options, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.vDataTable.options, "itemsPerPage", $event)},"update:options":function($event){return _vm.$set(_vm.vDataTable, "options", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-text-field',{attrs:{"dense":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.vDataTable.search),callback:function ($$v) {_vm.$set(_vm.vDataTable, "search", $$v)},expression:"vDataTable.search"}})],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"type":"info","border":"bottom","colored-border":"","elevation":"2"}},[_vm._v(" "+_vm._s(_vm.$t('noData'))+" ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"type":"warning","border":"bottom","colored-border":"","elevation":"2"}},[_vm._v(" "+_vm._s(_vm.stringFormat(_vm.$t('searchNotFound'),_vm.vDataTable.search))+" ")])]},proxy:true},{key:"footer",fn:function(){return [_c('tr',{staticClass:"sticky-table-footer",staticStyle:{"position":"absolute"}},[_c('td',[_vm._v("Selected("+_vm._s(_vm.vDataTable.selected.length)+")s")])])]},proxy:true}]),model:{value:(_vm.vDataTable.selected),callback:function ($$v) {_vm.$set(_vm.vDataTable, "selected", $$v)},expression:"vDataTable.selected"}})],1),_c('v-divider',{attrs:{"light":""}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary"},on:{"click":function($event){var valueSend = [];
        valueSend.actionReturn = 'OK'
        valueSend.dataSelected = _vm.vDataTable.selected
        _vm.sendToParentComponent(valueSend)}}},[_vm._v(" OK ")]),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary"},on:{"click":function($event){var valueSend = [];
        _vm.sendToParentComponent(valueSend)}}},[_vm._v(" Cancel ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card>
    <v-toolbar dense dark color="primary">
      <v-toolbar-title>Ref Code Browse</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="
        var valueSend = [];
        sendToParentComponent(valueSend)">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <!-- :single-select=true -->
      <v-data-table
        v-model="vDataTable.selected"
        :headers="headers"
        :items="vDataTable.data"
        :search="vDataTable.search"
        :single-select=true
        show-select
        fixed-header
        :page.sync="vDataTable.options.page"
        :itemsPerPage.sync="vDataTable.options.itemsPerPage"
        :options.sync="vDataTable.options"
        :footer-props="{
          itemsPerPageText: $t('itemsPerPageText'),
          itemsPerPageAllText: $t('itemsPerPageAllText'),
          showFirstLastPage: true,
          showCurrentPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
        }"
        item-key="skey"
        class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-text-field
              dense
              v-model="vDataTable.search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details>
            </v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:no-data>
          <v-alert type="info" border="bottom" colored-border elevation="2">
            {{$t('noData')}}
          </v-alert>
        </template>
        <template v-slot:no-results>
          <v-alert type="warning" border="bottom" colored-border elevation="2">
            {{stringFormat($t('searchNotFound'),vDataTable.search)}}
          </v-alert>
        </template>
        <template v-slot:footer>
          <tr class="sticky-table-footer" style="position: absolute" >
              <td>Selected({{vDataTable.selected.length}})s</td> 
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <v-divider light></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="mb-2"
        @click="
          var valueSend = [];
          valueSend.actionReturn = 'OK'
          valueSend.dataSelected = vDataTable.selected
          sendToParentComponent(valueSend)"
      >
      OK
      </v-btn>
      <v-btn
        color="primary"
        class="mb-2"
        @click="
          var valueSend = [];
          sendToParentComponent(valueSend)"
      >
      Cancel
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
  export default {
    props: ['receiveVar', 'parentComponent'],
    data () {
      return {
        utils: require('@/utils'),
        stringFormat: require('string-format'),
        appName: process.env.VUE_APP_DB_APP_NAME,
        dialog: {
          dialogProgress: false,
          dialogAddEditWindow: false,
          dialogPathoRegisterReport: false,
          dialogReceivedDateFromTo: false,
          dialogBrowseCustomer: false,
          dialogBrowsePhysician: false,
          dialogBrowsePathologist: false,
        },
        showMenu: false,
        x: 0,
        y: 0,
        vDataTable: {
          /* Standand Data */
          search: '',
          selected: [],
          data: [],
          editedIndex: -1,
          editedItem: {
            win_name: '',
            window_comment: ''
          },
          defaultItem: {
            win_name: '',
            window_comment: ''
          },
          options: {
            page: 1,
            itemsPerPage: -1,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: true,
            multiSort: true,
            // mustSort: true
          },
        },
        progressMessage: '',
        appSkey: -1,
        /* Standand Data */
        searchText: '',
        pagination: {
          sortBy: 'cust_no',
          rowsPerPage: 10
        },
        selected: [],
        data: [],
        headers: [
          { text: 'Ref. Code', value: 'ref_code', align: 'start', class: 'text-xl-left body-1 black--text font-weight-bold', width: '200px' },
          { text: 'Branch Name', value: 'name', align: 'start', class: 'text-xl-left body-1 black--text font-weight-bold', width: '150px' },
          { text: 'Partner', value: 'type_partner', align: 'start', class: 'text-xl-left body-1 black--text font-weight-bold', width: '150px' },
        ],
      }
    },
    created: function () {

    },
    methods: {
      sendToParentComponent (value) {
        this.vDataTable.selected = []
        value.childSend = this.$options._componentTag
        this.$emit('eventReturn', value)
      },
      retrieveRefCode () {
        this.axios.post(process.env.VUE_APP_API + '/user/retrieveRefCode' + process.env.VUE_APP_DATABASE_TYPE, {
        })
         .then(response => {
           if (response.data.isSuccess) {
             this.vDataTable.data = response.data.data
           } else {
             this.$swal({type: 'error', title: response.data.reasonText})
           }
         })
         .catch(e => {
           this.$swal({type: 'error', title: e.message})
         })
      },
      toggleAll () {
        if (this.vDataTable.selected.length) this.vDataTable.selected = []
        else this.vDataTable.selected = this.data.slice()
      },
      changeSort (column) {
        if (this.pagination.sortBy === column) {
          this.pagination.descending = !this.pagination.descending
        } else {
          this.pagination.sortBy = column
          this.pagination.descending = false
        }
      },
    }
  }
</script>

<template>
  <div id="page-selects">
    <!-- <h1> {{$t('userMaintTitle')}}</h1> -->
    <v-dialog v-model="dialogShowImage" persistent width="230">
      <v-card>
        <v-btn color="pink" dark small absolute right fab @click.native="hideToggleLockJobNumber">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text>
          <v-avatar
          :tile="true"
          :size="200"
          class="grey lighten-4"
          >
            <img :src="utils.imageProfile(selectedImage)" alt="" width="300" height="300">
          </v-avatar>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <v-container grid-list-xl fluid class="pa-2">
      <v-data-table
        v-model="vDataTable.selected"
        :headers="headersUser"
        :items="vDataTable.data"
        :search="vDataTable.search"
        fixed-header
        :page.sync="vDataTable.options.page"
        :itemsPerPage.sync="vDataTable.options.itemsPerPage"
        :options.sync="vDataTable.options"
        :height="$vuetify.breakpoint.height - 220"
        :footer-props="{
          itemsPerPageText: $t('itemsPerPageText'),
          itemsPerPageAllText: $t('itemsPerPageAllText'),
          showFirstLastPage: true,
          showCurrentPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
        }"
        item-key="user_id"
        class="elevation-1">
        <template v-slot:top>
          <v-row>
            <v-col cols="12" sm="12">
            <v-toolbar flat>
              <v-toolbar-title>User</v-toolbar-title>
            </v-toolbar>
            </v-col>
            <v-col cols="12" sm="12">
              <v-toolbar flat>
                <v-dialog v-model="dialog.dialogAddEditWindow" max-width="800px" eager>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined color="primary" @click="newUser()" v-bind="attrs" v-on="on" style="text-transform: capitalize">
                      <img src="../assets/SoftwareClinicIcon/New.png" height="30" weight="30"/>{{$t('user.buttonNewUser')}}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="6">  
                            <v-text-field outlined hide-details v-bind:label="$t('userID')" v-model="vDataTable.editedItem.user_id"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="4">
                            <v-text-field outlined hide-details label="Password"
                            v-model="vDataTable.editedItem.password"
                            :append-icon="e3 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="e3 ? 'password' : 'text'"
                            @click:append="e3 = !e3"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="2">
                            <v-checkbox
                              v-model="vDataTable.editedItem.temp_password"
                              label="Temp"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field outlined hide-details label="First Name" v-model="vDataTable.editedItem.first_name"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field outlined hide-details label="Last Name" v-model="vDataTable.editedItem.last_name"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-select class="ma-0 pa-0" outlined hide-details
                              :items="dropDownData.pc_profile_def"
                              item-value="profile_skey"
                              item-text="profile_name"
                              v-model="vDataTable.editedItem.profile_skey"
                              clearable
                              label="Profile">
                            </v-select>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field class="ma-0 pa-0" hide-details
                              readonly
                              outlined
                              dense
                              name="input-7-4"
                              label="Ref. Code"
                              v-model="vDataTable.editedItem.ref_code"
                              append-outer-icon="mdi-magnify"
                              @click:append-outer="FindRefCode()"
                            ></v-text-field>
                          </v-col>
                          <!-- <v-col cols="12" sm="12" md="6">
                            <v-text-field outlined hide-details mask="phone" v-model="vDataTable.editedItem.phone" label="Phone"></v-text-field>
                          </v-col> -->
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field outlined hide-details v-model="vDataTable.editedItem.email" label="Email"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                        <v-btn outlined color="primary" @click="save">{{$t('save')}}</v-btn>
                        <v-btn outlined color="primary" @click="close">{{$t('cancel')}}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- <v-divider class="mx-1" inset vertical></v-divider>
                <v-btn class="text-right" outlined color="primary" style="text-transform: capitalize" @click="resetPassword">
                  <img src="../assets/SoftwareClinicIcon/woman-working-on-a-computer.jpg" height="30" weight="30"/>Reset password
                </v-btn> -->
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="vDataTable.search"
                  dense
                  outlined
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details>
                </v-text-field>
              </v-toolbar>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="teal" small class="mr-6" v-bind="attrs" v-on="on" @click="editItem(item)">edit</v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="red" v-show="item.user_id !== 'admin' && item.i_lock === 1" small class="mr-6" v-bind="attrs" v-on="on" @click="unlock(item.user_id)">lock</v-icon>
            </template>
            <span>Unlock</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="black" v-show="item.user_id !== 'admin'" small class="mr-6" v-bind="attrs" v-on="on" @click="genQRCode(item.user_id)">qr_code_2</v-icon>
            </template>
            <span>QR Code</span>
          </v-tooltip>
          <!-- <v-icon color="teal" v-show="item.user_id !== 'admin'" small class="mr-6" @click="editItem(item)">edit</v-icon>
          <v-icon color="teal" v-show="item.user_id !== 'admin'" small class="mr-6" @click="editItem(item)">lock</v-icon> -->
        </template>
        <!-- <template v-slot:item.actions="{ item }">
          <v-icon color="teal" v-show="item.user_id !== 'admin'" small class="mr-6" @click="editItem(item)">edit</v-icon>
        </template> -->
        <template v-slot:item.actionsDel="{ item }">
          <v-icon color="pink" v-show="item.user_id !== 'admin'" small @click="deleteItemConfirm(item)">delete</v-icon>
        </template>
        <template v-slot:item.data-table-no="{ item, index }">
          {{index + 1}}
        </template>
        <template v-slot:no-data>
          <v-alert type="info" border="bottom" colored-border elevation="2">
            {{$t('noData')}}
          </v-alert>
        </template>
        <template v-slot:no-results>
          <v-alert type="warning" border="bottom" colored-border elevation="2">
            {{stringFormat($t('searchNotFound'),vDataTable.search)}}
          </v-alert>
        </template>
        <template v-slot:footer>
          <tr class="sticky-table-footer" style="position: absolute" >
              <td>Selected({{vDataTable.selected.length}})s</td> 
          </tr>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog.dialogProgress" persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            <span class="font-weight-bold dark white--text">{{progressMessage}}</span>
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog.dialogBrowseBranchRefCode" max-width="800px" scrollable eager>
        <BranchRefCodeBrowse ref="BranchRefCodeBrowse"
          :receiveVar="sendVar" @eventReturn="getVarReturn">
        </BranchRefCodeBrowse>
      </v-dialog>
      <v-dialog v-model="dialog.dialoggenQRCode" max-width="300px" scrollable eager>
        <v-card>
          <!-- <qrcode-vue :value="valueQrcode" :size="size" level="H" /> -->
          <v-card-title>
            <qr-code :text="valueQrcode" ref="qrCode"></qr-code>
          </v-card-title>
          <v-card-actions>
              <v-btn outlined color="success" @click="saveAsPicture" >save as</v-btn>
              <v-btn outlined color="error" @click="dialog.dialoggenQRCode = false">close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import Base64Binary from 'base64-arraybuffer'
import VWidget from '@/components/VWidget'
import BranchRefCodeBrowse from '@/components/BranchRefCodeBrowse'
import VueQRCodeComponent from 'vue-qrcode-component'
import html2canvas from 'html2canvas'
/* import QrcodeVue from 'qrcode.vue' */
// import { imageProfile } from '../utils'

export default {
  components: {
    BranchRefCodeBrowse,
    VueQRCodeComponent,
    /* QrcodeVue */
  },
  props: ['parentComponent'],

  data () {
    return {
      utils: require('@/utils'),
      stringFormat: require('string-format'),
      appName: process.env.VUE_APP_DB_APP_NAME,
      dialog: {
        dialogProgress: false,
        dialogAddEditWindow: false,
        dialogBrowseBranchRefCode: false,
        dialoggenQRCode: false,
      },
      showMenu: false,
      x: 0,
      y: 0,
      vDataTable: {
        search: '',
        selected: [],
        data: [],
        editedIndex: -1,
        editedItem: {
          user_id: '',
          first_name: '',
          last_name: '',
          password: '',
          phone: '',
          email: '',
          sex: '',
        },
        defaultItem: {
          user_id: '',
          first_name: '',
          last_name: '',
          password: '',
          phone: '',
          email: '',
          sex: '',
        },
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: true,
          multiSort: true,
          // mustSort: true
          valueQrcode: 'https://example.com',
          size: 300,
        },
      },
      progressMessage: '',
      appSkey: -1,
      e3: true,
      dialogShowImage: false,
      selectedImage: null,
      dialogAddEditWindow: false,
      dialogProgress: false,
      searchUser: '',
      paginationUser: {
        sortBy: 'user_id',
        rowsPerPage: 10
      },
      selectedUser: [],
      dataUser: [],
      editedIndex: -1,
      editedItem: {
        user_id: '',
        first_name: '',
        last_name: '',
        password: '',
        phone: '',
        email: '',
        sex: '',
      },
      deletedIndex: -1,
      deletedItem: {
        user_id: '',
        first_name: '',
        last_name: '',
        password: '',
        phone: '',
        email: '',
      },
      defaultItem: {
        user_id: '',
        first_name: '',
        last_name: '',
        password: '',
        phone: '',
        email: '',
        sex: '',
      },
      dropDownData: [],
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      regPassword: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^0-9a-zA-Z]).{8,26}$/,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },

  computed: {
    formTitle () {
      return this.vDataTable.editedIndex === -1 ? 'New User' : 'Edit User'
    },
    /* headers () {
      return [
        { text: '#', align: 'center', value: 'data-table-no' },
        { text: '', align: 'center', value: 'data-table-select' },
        { text: 'win_skey', align: 'left', value: 'win_skey' },
        { text: this.$t('actions'), value: 'actions', sortable: false },
        { text: this.$t('navWindow.windowName'), value: 'win_name', align: 'right' },
        { text: this.$t('navWindow.windowComment'), value: 'window_comment', align: 'left' },
        { text: this.$t('createUser'), value: 'create_user_id', align: 'left' },
        { text: this.$t('createDate'), value: 'create_date', align: 'left' },
        { text: this.$t('maintUser'), value: 'maint_user_id', align: 'left' },
        { text: this.$t('maintDate'), value: 'maint_date', align: 'left' },
        { text: 'File', value: 'win_dll_name', align: 'left' },
      ]
    } */
    headersUser () {
      return [
        { text: '#', align: 'center', value: 'data-table-no', class: 'text-xl-left body-1 black--text' },
        /* { text: '', align: 'center', value: 'data-table-select', class: 'text-xl-left body-1 black--text' }, */
        { text: this.$t('userID'), align: 'left', value: 'user_id', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('actions'), sortable: false, value: 'actions', class: 'text-xl-left body-1 black--text' },
        /* { text: this.$t('picture'), value: 'picture_base64', align: 'left' }, */
        { text: this.$t('firstName'), value: 'first_name', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('lastName'), value: 'last_name', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: 'Profile', value: 'profile_name', align: 'left', class: 'text-xl-left body-1 black--text' },
        /* { text: this.$t('employeeNo'), value: 'employee_no', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('facilityCode'), value: 'facility_cd', align: 'left', class: 'text-xl-left body-1 black--text' }, */
        { text: 'Ref Code', value: 'ref_code', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: 'ลบ', sortable: false, value: 'actionsDel', class: 'text-xl-left body-1 black--text' },
        /* { text: 'i_lock', value: 'i_lock', align: 'left', class: 'text-xl-left body-1 black--text' }, */
      ]
    }
  },

  watch: {
    'dialog.dialogAddEditWindow' (val) {
      val || this.close()
    }
  },

  created: function () {
    this.retrieveUser()
    this.retrieveDropDown()
  },

  mounted () {
  },

  updated () {
  },

  methods: {
    selectLanguage (language) {
      this.vDataTable.data = []
    },
    initialize () {
      this.vDataTable.selected = []
      this.vDataTable.editedIndex = -1
      this.vDataTable.editedItem = {
        win_name: '',
        window_comment: '',
        user_id: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        password: '',
      }
      this.vDataTable.defaultItem = {
        win_name: '',
        window_comment: '',
        user_id: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        password: '',
      }
    },
    showToggleLockJobNumber (picture) {
      this.dialogShowImage = true
      this.selectedImage = picture
    },
    hideToggleLockJobNumber () {
      this.dialogShowImage = false
    },
    printReportUserInfo (item) {
      this.dialog.dialogProgress = true
      this.progressMessage = 'กรุณารอสักครู่'
      var dataJSON = []
      var data = []
      data.push({ 'user_id': item.user_id, 'first_name': item.first_name, 'last_name': item.last_name, 'employee_no': item.employee_no == null ? '' : item.employee_no, 'facility_cd': item.facility_cd == null ? '' : item.facility_cd, 'picture_base64': item.picture_base64 == null ? 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=' : item.picture_base64 })
      dataJSON.push({'data': data})
      this.axios.post(process.env.VUE_APP_API + '/getReportByJSONBase64', {
        report_name: 'user_info',
        data_json: dataJSON}, {})
        .then(response => {
          if (response.data.isSuccess) {
            const blob = new Blob([Base64Binary.decode(response.data.pdf_base64)], {type: 'application/pdf; charset=utf-8'})
            const url = window.URL.createObjectURL(blob)
            window.open(url)
            this.dialog.dialogProgress = false
          } else {
            this.dialog.dialogProgress = false
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.dialog.dialogProgress = false
          this.$swal({type: 'error', title: e.message})
        })
    },
    async retrieveUser () {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      await this.axios.post(process.env.VUE_APP_API + '/user/retrieveUser' + process.env.VUE_APP_DATABASE_TYPE)
        .then(response => {
          if (response.data.isSuccess) {
            this.dialog.dialogProgress = false
            this.vDataTable.data = response.data.data
          } else {
            this.dialog.dialogProgress = false
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.dialog.dialogProgress = false
          this.$swal({type: 'error', title: e.message})
        })
    },
    retrieveDropDown () {
      this.axios.post(process.env.VUE_APP_API + '/user/retrieveDropDown' + process.env.VUE_APP_DATABASE_TYPE, {
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.dropDownData = response.data.data[0]
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async addUser (userID, password, firstName, lastName, phone, email, profileSkey, refCode, tempPassword) {
      if (!email) { email = '' }
      if (!lastName) { lastName = '' }
      if (!refCode) { refCode = '' }
      if (!tempPassword) { tempPassword = 0 }
      await this.axios.post(process.env.VUE_APP_API + '/user/addUser' + process.env.VUE_APP_DATABASE_TYPE, {
        user_id: userID,
        password: password,
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        email: email,
        profile_skey: profileSkey,
        ref_code: refCode,
        temp_password: tempPassword
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.vDataTable.data.push(this.vDataTable.editedItem)
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
            this.retrieveUser()
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async updateUser (userID, password, passwordOrig, firstName, lastName, phone, email, profileSkey, refCode, tempPassword) {
      if (!email) { email = '' }
      if (!lastName) { lastName = '' }
      if (!refCode) { refCode = '' }
      if (password === passwordOrig) { password = '' }
      if (!tempPassword) { tempPassword = 0 }
      await this.axios.post(process.env.VUE_APP_API + '/user/updateUser' + process.env.VUE_APP_DATABASE_TYPE, {
        user_id: userID,
        password: password,
        password_orig: passwordOrig,
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        email: email,
        profile_skey: profileSkey,
        ref_code: refCode,
        temp_password: tempPassword,
      })
        .then(response => {
          if (response.data.isSuccess) {
            Object.assign(this.vDataTable.data[this.vDataTable.editedIndex], this.vDataTable.editedItem)
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
            this.retrieveUser()
            this.close()
          } else {
            if (response.data.reasonText === 'Error1') {
              this.$swal({type: 'warning', title: 'รหัสผ่านใหม่ต้องไม่ซ้ำกับรหัสผ่านเก่า'})
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    deleteUser (item) {
      var userID = this.deletedItem.user_id
      this.dataUser.splice(this.deletedIndex, 1)
      userID = item.user_id
      this.axios.post(process.env.VUE_APP_API + '/user/deleteUser' + process.env.VUE_APP_DATABASE_TYPE, {
        user_id: userID
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.retrieveUser()
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    toggleAll () {
      if (this.selectedUser.length) this.selectedUser = []
      else this.selectedUser = this.dataUser.slice()
    },
    changeSort (column) {
      if (this.paginationUser.sortBy === column) {
        this.paginationUser.descending = !this.paginationUser.descending
      } else {
        this.paginationUser.sortBy = column
        this.paginationUser.descending = false
      }
    },
    /* editItem (item) {
      this.editedIndex = this.dataUser.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogAddEditWindow = true
    }, */
    editItem (item) {
      this.vDataTable.editedIndex = this.vDataTable.data.indexOf(item)
      this.vDataTable.editedItem = Object.assign({}, item)
      this.dialog.dialogAddEditWindow = true
    },
    async deleteItemConfirm (item) {
      if (item.user_id === 'admin') {
        this.$swal({type: 'warning', title: 'User: admin ไม่สามารถลบออกจากระบบได้'})
        return
      }
      this.vDataTable.editedIndex = this.vDataTable.data.indexOf(item)
      this.vDataTable.editedItem = Object.assign({}, item)
      this.$swal({
        text: 'คุณต้องการที่จะลบข้อมูล User?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      }).then(async (result) => {
        if (result) {
          var val = await this.deleteUser(this.vDataTable.editedItem)
          if (val !== undefined) {
            this.vDataTable.selected.splice(this.vDataTable.selected.indexOf(this.vDataTable.editedItem), 1)
          }
          this.vDataTable.editedItem = Object.assign({}, this.vDataTable.defaultItem)
          this.vDataTable.editedIndex = -1
        }
      })
    },
    close () {
      this.dialog.dialogAddEditWindow = false
      this.$nextTick(() => {
        this.vDataTable.editedItem = Object.assign({}, this.vDataTable.defaultItem)
        this.vDataTable.editedIndex = -1
      })
    },
    async save () {
      if (this.validateReg() === true) {
        if (this.vDataTable.editedIndex > -1) {
          await this.updateUser(this.vDataTable.editedItem.user_id,
            this.vDataTable.editedItem.password,
            this.vDataTable.editedItem.password_orig,
            this.vDataTable.editedItem.first_name,
            this.vDataTable.editedItem.last_name,
            this.vDataTable.editedItem.phone,
            this.vDataTable.editedItem.email,
            this.vDataTable.editedItem.profile_skey,
            this.vDataTable.editedItem.ref_code,
            this.vDataTable.editedItem.temp_password)
        } else {
          await this.addUser(this.vDataTable.editedItem.user_id,
            this.vDataTable.editedItem.password,
            this.vDataTable.editedItem.first_name,
            this.vDataTable.editedItem.last_name,
            this.vDataTable.editedItem.phone,
            this.vDataTable.editedItem.email,
            this.vDataTable.editedItem.profile_skey,
            this.vDataTable.editedItem.ref_code,
            this.vDataTable.editedItem.temp_password)
          this.close()
        }
      }
    },
    validateReg () {
      var messageCustom = ''
      if (!this.vDataTable.editedItem.user_id) {
        /* messageCustom = messageCustom + his.$t('EgatWebEmployee.messageEmployeeID') + '<br />' */
        messageCustom = messageCustom + 'Please Enter User ID' + '<br />'
      }
      if (!this.vDataTable.editedItem.first_name) {
        /* messageCustom = messageCustom + his.$t('EgatWebEmployee.messageEmployeeID') + '<br />' */
        messageCustom = messageCustom + 'Please Enter First Name' + '<br />'
      }
      if (!this.vDataTable.editedItem.password) {
        /* messageCustom = messageCustom + his.$t('EgatWebEmployee.messageEmployeeID') + '<br />' */
        messageCustom = messageCustom + 'Please Enter Password' + '<br />'
      }
      if (!this.vDataTable.editedItem.profile_skey) {
        /* messageCustom = messageCustom + his.$t('EgatWebEmployee.messageEmployeeID') + '<br />' */
        messageCustom = messageCustom + 'Please Enter Profile' + '<br />'
      }
      if (this.vDataTable.editedItem.email) {
        if (!this.reg.test(this.vDataTable.editedItem.email)) {
          messageCustom = messageCustom + this.$t('login.invalidEmail') + '<br />'
        }
      }
      if (this.vDataTable.editedItem.password !== this.vDataTable.editedItem.password_orig) {
        if (!this.regPassword.test(this.vDataTable.editedItem.password)) {
          messageCustom = this.$t('login.invalidPassword') + '<br />'
        }
      }
      if (messageCustom) {
        this.$swal({type: 'warning', html: messageCustom})
        return false
      } else {
        return true
      }
    },
    resetPassword () {
      this.$swal({
        text: 'Reset Password',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      }).then((result) => {
        if (result) {
          this.dialog.dialogProgress = true
          this.progressMessage = this.$t('waiting')
          this.axios.post(process.env.VUE_APP_API + '/user/resetPassword' + process.env.VUE_APP_DATABASE_TYPE)
            .then(response => {
              if (response.data.isSuccess) {
                this.dialog.dialogProgress = false
                this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
              } else {
                this.dialog.dialogProgress = false
                this.$swal({type: 'error', title: response.data.reasonText})
              }
            })
            .catch(e => {
              this.dialog.dialogProgress = false
              this.$swal({type: 'error', title: e.message})
            })
        }
      })
    },
    unlock (userID) {
      this.$swal({
        text: 'คุณต้องการที่จะ unlock user ใช่หรือไม่',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      }).then((result) => {
        if (result) {
          this.dialog.dialogProgress = true
          this.progressMessage = this.$t('waiting')
          this.axios.post(process.env.VUE_APP_API + '/user/unlock' + process.env.VUE_APP_DATABASE_TYPE, {
            user_id: userID
          })
            .then(response => {
              console.log(response.data, 'response.dataresponse.dsfdsfdsfs')
              if (response.data.isSuccess) {
                this.retrieveUser()
                this.dialog.dialogProgress = false
                this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
              } else {
                this.dialog.dialogProgress = false
                this.$swal({type: 'error', title: response.data.reasonText})
              }
            })
            .catch(e => {
              this.dialog.dialogProgress = false
              this.$swal({type: 'error', title: e.message})
            })
        }
      })
    },
    /* genQRCode (userID) {
      this.dialog.dialoggenQRCode = true
      var base64ParmUser = btoa(userID)
      console.log(userID, 'userID')
      console.log(base64ParmUser, 'base64ParmUser')
      this.valueQrcode = this.stringFormat('http://aphrozone.dyndns.org/MeeTeeMeeNgern/Register?u={}?', base64ParmUser)
    }, */
    genQRCode (userID) {
      this.axios.post(process.env.VUE_APP_API + '/user/getGlobalUrlRegisterRefCode', {
      })
        .then(response => {
          if (response.data.isSuccess) {
            /* this.ShowForgotPassword = response.data.data */
            this.dialog.dialoggenQRCode = true
            var base64ParmUser = btoa(userID)
            console.log(userID, 'userID')
            console.log(base64ParmUser, 'base64ParmUser')
            this.valueQrcode = this.stringFormat('{}{}?', response.data.data, base64ParmUser)
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    FindRefCode () {
      this.dialog.dialogBrowseBranchRefCode = true
      this.$refs.BranchRefCodeBrowse.retrieveRefCode()
    },
    getVarReturn (value) {
      var count = 1
      console.log(value.childSend, 'value.childSend')
      switch (value.childSend) {
        case 'BranchRefCodeBrowse':
          this.dialog.dialogBrowseBranchRefCode = false
          switch (value.actionReturn) {
            case 'OK':
              for (let data of value.dataSelected) {
                this.vDataTable.editedItem.ref_code = data.ref_code
              }
          }
          break
      }
    },
    newUser () {
      console.log('vDataTable.editedItem.temp_password')
      this.vDataTable.editedItem.temp_password = true
    },
    saveAsPicture () {
      console.log('sssssssss')
      // Get the element you want to capture
      const elementToCapture = this.$refs.qrCode.$el
      // Use html2canvas to capture the element as an image
      html2canvas(elementToCapture).then((canvas) => {
        // Convert the canvas to a data URL
        const dataURL = canvas.toDataURL()
        // Create a link element and trigger a download
        const link = document.createElement('a')
        link.href = dataURL
        link.download = 'qrcode.png'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
  }
}
</script>

<style lang="css">
.dialog.centered-dialog,
.v-dialog.centered-dialog
{
  background: #282c2dad;
  box-shadow: none;
  border-radius: 6px;
  width: auto;
  color: whitesmoke;
},
.DataTable
{
    display: none;
    border: none;
},
.rounded-card
{
  display: none;
  border: none;
}
</style>